import { useAppSelector } from 'srs.sharedcomponents/lib/esm/redux/hooks'
import { Container, Row, Col } from 'react-bootstrap'
import LeftMenuComponent from 'srs.sharedcomponents/lib/esm/components/molecules/LeftMenuComponent/LeftMenuComponent'
import { stringToBoolean } from 'srs.sharedcomponents/lib/esm/utils/helpers'
import BuilderModelComponent from 'srs.sharedcomponents/lib/esm/components/builder/srs.builderModel.components'
import { IBuilderModelParameter } from 'srs.sharedcomponents/lib/esm/models/builder/builderModelParameter.model'
import { getWebsiteName } from 'srs.sharedcomponents/lib/esm/utils/websiteName'
import { IWelcomeComponentProps } from 'srs.sharedcomponents/lib/esm/shared-components/srs.welcome.component'

const EssentialBulletins = (props: IWelcomeComponentProps ) => {
  const welcome = useAppSelector((state) => state.welcome.welcome)
  const leftNavEnabled = stringToBoolean(process.env.REACT_APP_LEFT_NAVIGATION_ENABLED)

  const getHomeParameters = () => {
    const websiteName = getWebsiteName()?.websiteName
    if (websiteName) {
        const homeParameters: IBuilderModelParameter = 
        { 
            websiteName: websiteName
        }
        return homeParameters;
    }
    else return {}
  }
  
if (!welcome ) return <></>
else return (
        <Container>
            <main id="main" role="main" className="pb-3">
                <Row>
                    {leftNavEnabled && (
                        <Col
                        className={`${
                            props.isLeftSideMobileMenuExpand ? '' : 'd-none '
                        } d-xl-block d-lg-block`}
                        >
                            <LeftMenuComponent
                                items={welcome.leftCatalog}
                                toggleLeftSideMobileMenu={props.toggleLeftSideMobileMenu}
                            />
                        </Col>
                    )}
                    <Col Col md={12} lg={leftNavEnabled ? 9 : 12} xl={leftNavEnabled ? 9 : 12}>
                        <Row>
                            <div className="col-12">
                                <BuilderModelComponent 
                                    modelKey='essentialBulletinsModel' 
                                    path='/essential-bulletins' 
                                    modelName='content-page' 
                                    modelParameters={getHomeParameters()} 
                                />
                            </div>
                        </Row>
                    </Col>
                </Row>
            </main>
        </Container>
    )
}
export default EssentialBulletins