import { Colors, globalTheme } from 'srs.sharedcomponents/lib/esm/styles/config'

const hdThemeColor = '#f36721';
export const customTheme = {
  colors: {
    ...globalTheme.colors,
    primary: hdThemeColor,
    menuButtonMobile: Colors.white,
    menuButton: Colors.white,
    cartButtonMobile: Colors.filterWhite,
    topNavigationMobile: Colors.white,
    continueShoppingButton: hdThemeColor,
  },
}