import { componentsConfig as defaultConfig } from "srs.sharedcomponents/lib/esm/components/config";
import EssentialBulletins from "../EssentialBulletins/EssentialBulletins"
import WelcomeBuilder from "srs.sharedcomponents/lib/esm/components/organisms/Welcome/BuilderContent/WelcomeBuilder"
import DigitalTechnician from "../DigitalTechnician/DigitalTechnician";
import SelectRegionComponent from "srs.sharedcomponents/lib/esm/components/region/SelectRegion";

const componentsConfig = { 
    ...defaultConfig, 
    WelcomeContent: WelcomeBuilder,
    EssentialBulletins: EssentialBulletins,
    DigitalTechnician: DigitalTechnician,
    SelectRegion: SelectRegionComponent
};

export { componentsConfig };